import React, { useState } from "react";
import { useSelector } from "react-redux";
import { graphql, Link } from "gatsby";
import styled from "styled-components";

import { BREAKPOINTS, COLORS, LAYOUT } from "../../settings";
import { translator } from "../../helpers/lang";
import { useBonds } from "../../hooks/use-bonds";
import { Translator } from "../Translator";
import { SliderBonds } from "./SliderBonds";

import icoBlockHeadingLinkArrow from "../../images/block_heading_link_arrow.svg";
import icoBlockHeadingLinkArrowBlue from "../../images/block_heading_link_arrow_blue.svg";
import { IconArrow } from "../Icons/IconArrow";

export const ProductsBlock = ({ data }) => {
  const dataBase = useSelector(state => state.base);
  const langCurrent = dataBase.lang.current;
  const dataBonds = useBonds();
  const [activeRegion, setActiveRegion] = useState(
    dataBonds.regions[0].region_name
  );

  const sliderPosition = (activeRegion, regions) => {
    const count = dataBonds.regions.length;
    const regionIndex = dataBonds.regions.findIndex(
      element => element.region_name === activeRegion
    );

    return (100 / count) * regionIndex;
  };

  const clickHandler = () => {
    const count = dataBonds.regions.length;
    const regionIndex = dataBonds.regions.findIndex(
      element => element.region_name === activeRegion
    );
    const nextRegion =
      regionIndex + 1 >= count
        ? dataBonds.regions[0].region_name
        : dataBonds.regions[regionIndex + 1].region_name;

    setActiveRegion(nextRegion);
  };

  return (
    <StyledProductsBlock className="block-container">
      <div className="block-title">
        <h2 className="h2 block-title_title">
          <Translator
            langCode={langCurrent}
            translations={{
              ru: "Инвестиционные продукты",
              en: "Investment products",
              kz: "Инвестициялық өнімдер",
            }}
          />
        </h2>
        <Link
          to={translator(langCurrent, {
            ru: "/investors/#invest-products",
            en: "/en/investors-en/#invest-products",
            kz: "/kz/investors-kz/#invest-products",
          })}
          className="block-title_link"
        >
          <Translator
            langCode={langCurrent}
            translations={{
              ru: "Инвестиции",
              en: "Investments",
              kz: "Инвестициялар",
            }}
          />
        </Link>
      </div>
      <StyledPanels className="products">
        {/* <StyledLoanInvestmentPanel className="products_item product product__investment"> //
          <span className="product_intro">
            <Translator
              translations={{
                ru: "Инвестиция в займы",
                en: "Loan Investments",
                kz: "Қарыздарға инвестиция",
              }}
              langCode={langCurrent}
            />
          </span>
          <h3 className="product_title h3">
            <Link to={data.loan_investment.title.url}>
              {data.loan_investment.title.title}
            </Link>
          </h3>
          <p className="product_rate">
            <span className="product_rate-intro">
              <Translator
                translations={{
                  ru: "Доходность",
                  en: "Profitability",
                  kz: "Табыстылығы",
                }}
                langCode={langCurrent}
              />
            </span>
            <span className="product_rate-data number number__super">
              {data.loan_investment.rate}
            </span>
          </p>
          <div className="product_bottom">
            <Link
              to={translator(langCurrent, {
                ru: "/investors/#invest-products",
                en: "/en/investors-en/#invest-products",
                kz: "/kz/investors-kz/#invest-products",
              })}
            >
              <Translator
                langCode={langCurrent}
                translations={{
                  ru: "Подробнее",
                  en: "More",
                  kz: "Толығырақ",
                }}
              />
            </Link>
          </div>
        </StyledLoanInvestmentPanel> */}
        <StyledBondInvestmentPanel className="products_item product product__bonds">
          <span className="product_intro">
            <Translator
              translations={{
                ru: "Инвестиции в облигации",
                en: "Bond Investments",
                kz: "Облигацияларға инвестиция",
              }}
              langCode={langCurrent}
            />
          </span>
          {dataBonds.regions && langCurrent !== null && (
            <>
              {/*<StyledToggle
                activeRegion={activeRegion}
                position={sliderPosition(activeRegion, dataBonds.regions)}
                onClick={clickHandler}
              >
                {dataBonds.regions.map((region, id) => (
                  <span key={`reg1${id}`}>{region.region_name}</span>
                ))}
              </StyledToggle>*/}
              {dataBonds.regions.map((region, id) => {
                return region.region_name === activeRegion ? (
                  <SliderBonds key={`reg2${id}`}>
                    {region.bonds &&
                      region.bonds.map((bond, id) => (
                        <div key={`bond${id}`} className="product_card">
                          <h3 className="product_title h3">
                            <Link
                              to={
                                bond.bond_name["bond_name_" + langCurrent].url
                              }
                            >
                              {bond.bond_name["bond_name_" + langCurrent].title}
                            </Link>
                          </h3>
                          <p className="product_subtitle">{bond.bond_isin}</p>
                          <p className="product_rate">
                            <span className="product_rate-intro">
                              <Translator
                                translations={{
                                  ru: "Доходность",
                                  en: "Profitability",
                                  kz: "Табыстылығы",
                                }}
                                langCode={langCurrent}
                              />
                            </span>
                            <span className="product_rate-data number number__super">
                              {bond.bond_rate}
                            </span>
                          </p>
                        </div>
                      ))}
                  </SliderBonds>
                ) : null;
              })}
            </>
          )}
          <div className="product_bottom">
            <Link
              to={translator(langCurrent, {
                ru: "/investors/#invest-products",
                en: "/en/investors-en/#invest-products",
                kz: "/kz/investors-kz/#invest-products",
              })}
            >
              <Translator
                langCode={langCurrent}
                translations={{
                  ru: "Подробнее",
                  en: "More",
                  kz: "Толығырақ",
                }}
              />
            </Link>
          </div>
        </StyledBondInvestmentPanel>
      </StyledPanels>
      <Link
        to={translator(langCurrent, {
          ru: "/investors/#invest-products",
          en: "/en/investors-en/#invest-products",
          kz: "/kz/investors-kz/#invest-products",
        })}
        className="products_link button button__secondary button__with-arrow"
      >
        <span>
          <Translator
            langCode={langCurrent}
            translations={{
              ru: "Инвестиции",
              en: "Investments",
              kz: "Инвестициялар",
            }}
          />
          <IconArrow size={20} />
        </span>
      </Link>
    </StyledProductsBlock>
  );
};

const StyledProductsBlock = styled.div`
  .block-title {
    display: flex;
    padding: 0 0 15px;
    border-bottom: 1px solid ${COLORS.lines};
    margin: 0 0 40px;

    justify-content: space-between;
    align-items: baseline;

    @media (max-width: ${BREAKPOINTS.mobile_xs - 1 + "px"}) {
      font-size: 18px;
    }

    .block-title_title {
      margin: 0 auto 0 0;
    }

    .block-title_link {
      display: none;
      color: ${COLORS.text};
      font-size: 16px;
      line-height: 25px;

      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        display: inline-block;
      }

      &::after {
        content: "";
        vertical-align: -5px;

        display: inline-block;
        background: url(${icoBlockHeadingLinkArrow}) center/contain no-repeat;
        width: 20px;
        height: 20px;
        margin: 0 0 0 5px;
      }
    }
  }

  .products_link {
    display: inline-block;
    margin: 30px 0 0;
    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      display: none;
    }
  }
`;

const StyledPanels = styled.div`
  margin: ${-1 * LAYOUT.gap + "px"};

  @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
    display: flex;
    margin: ${(-1 * LAYOUT.gap_tablet) / 2 + "px"};
  }

  @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
    margin: ${(-1 * LAYOUT.gap_desktop) / 2 + "px"};
  }

  .products_item {
    display: flex;
    flex-direction: column;
    min-height: 225px;
    background: ${COLORS.bg_light};
    padding: 30px 20px;
    border-radius: 7px;
    margin: ${LAYOUT.gap + "px"};

    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      min-height: 245px;
      padding: 30px 20px;
      margin: ${LAYOUT.gap_tablet / 2 + "px"};
    }

    @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
      min-height: 295px;
      padding: 40px 35px 35px;
      margin: ${LAYOUT.gap_desktop / 2 + "px"};
    }
  }

  .product_card {
    display: flex !important;

    height: 100%;

    flex-direction: column;
  }

  .product_intro,
  .product_rate-intro {
    display: block;
    color: ${COLORS.text_secondary};
    font-size: 15px;
    line-height: 20px;

    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      font-size: 15px;
      line-height: 25px;
    }

    @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
      font-size: 16px;
      line-height: 25px;
    }
  }

  .product_intro {
    margin: 0 0 10px;

    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      margin: 0 0 15px;
    }

    @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
      margin: 0 0 15px;
    }
  }

  .product_title {
    margin: 0;

    @media (max-width: ${BREAKPOINTS.mobile_xs - 1 + "px"}) {
      font-size: 18px;
    }

    a {
      color: currentColor;

      &:hover,
      &:focus {
        color: ${COLORS.text_accent};
        text-decoration: none;
      }
    }
  }

  .product_subtitle {
    margin: 5px 0 0;
    color: ${COLORS.text_secondary};
    font-size: 15px;
    line-height: 25px;

    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      font-size: 15px;
      line-height: 25px;
    }

    @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
      font-size: 16px;
      line-height: 25px;
    }
  }

  .product_rate {
    margin: auto 0 0;
  }

  .product_bottom {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media (max-width: ${BREAKPOINTS.desktop_lg + "px"}) {
      flex-wrap: wrap;
    }

    a {
      margin-top: 10px;
      font-size: 16px;
      line-height: 25px;
      color: #0083e5;

      &::after {
        content: "";
        vertical-align: -5px;

        display: inline-block;
        background: url(${icoBlockHeadingLinkArrowBlue}) center/contain
          no-repeat;
        width: 20px;
        height: 20px;
        margin: 0 0 0 5px;
      }
    }
  }
`;

const StyledLoanInvestmentPanel = styled.div`
  @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
    width: 40%;
  }
`;

const StyledBondInvestmentPanel = styled.div`
  overflow: hidden;
  position: relative;

  @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
    width: 100%;
  }

  .product_intro {
    max-width: calc(100% - 105px);

    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      max-width: none;
    }
  }
`;

const StyledToggle = styled.button`
  overflow: hidden;

  position: absolute;
  top: 20px;
  right: 20px;
  background: #efeff0;
  padding: 2px;
  border: none;
  border-radius: 7px;
  font-size: 16px;
  line-height: 25px;
  text-align: center;

  @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
    top: 25px;
    right: 20px;
  }

  @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
    top: 30px;
    right: 40px;
  }

  &::before {
    content: "${props => props.activeRegion}";
    pointer-events: none;

    position: absolute;
    top: 2px;
    left: ${props => props.position + 2 + "px"};
    background: #ffffff;
    width: 50px;
    padding: 4px 14px;
    border: 0.5px solid rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.12), 0 3px 1px rgba(0, 0, 0, 0.04);
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    transition: left 0.3s ease;
  }

  span {
    display: inline-block;
    width: 50px;
    padding: 4px 14px;
    color: ${COLORS.text};
  }
`;

export const query = graphql`
  fragment ProductsBlock on WordPressAcf_products_block {
    id
    loan_investment {
      title {
        url
        title
      }
      rate
    }
  }
`;
