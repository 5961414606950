import { useStaticQuery, graphql } from 'gatsby';

export const useTeamList = () => {
    const { wordpressAcfOptions } = useStaticQuery(
        graphql`
            query wordpressAcfOptions {
                wordpressAcfOptions {
                    options {
                        ot_team_list {
                            ot_country {
                                label
                                value
                            }
                            ot_image {
                                alt_text
                                localFile {
                                    publicURL
                                }
                            }
                            ot_name {
                                lang
                                title
                            }
                            ot_title {
                                lang
                                title
                            }
                            ot_position {
                                lang
                                title
                            }
                            ot_project {
                                label
                                value
                            }
                            is_add_to_vacancy_page
                            cp_description {
                                lang
                                title
                            }
                        }
                    }
                }
            }
        `
    );

    return wordpressAcfOptions.options.ot_team_list;
};
